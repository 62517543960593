<template>
    <div class="qr-code-block">
        <van-nav-bar
            title="缴纳学费"
            left-text=""
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        
        <div class="bc-white m-t-10 m-l-20 m-r-20 br-10 padding-b-10 content">
            <div v-if="qrCodeUrl" class="text-align b-b" style="padding: 25px 30px 5px;">
                <img :src="qrCodeUrl" style="width: 100%;">
                <p class="m-10" style="color: #07c160;">识别二维码支付</p>
            </div>
            <div class="padding-10-20">
                <div class="l-h-30 font-18">缴费说明</div>
                <div v-html="description" class="grey font-15" style="text-align:justify;"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                qrCodeUrl:'', // 二维码url
                description:''
            }
        },
        mounted() {
            this.getData()
        },
        methods:{
            onClickLeft(){
                this.$router.go(-1);
            },
            getData(){
                this.$api.common.getSchoolInfo().then((res) => {
                    let data = res.data
                    // 设置title
                    document.title = data.title
                    let timeStatus = this.isDuringDate(data.paystart, data.payend)
                    
                    if(timeStatus && data.thumb){
                        this.qrCodeUrl = data.thumb
                        this.description = data.description
                    }else{
                        this.$dialog.alert({
                            message: '缴费通道暂开始，请持续关注学校公告！',
                            confirmButtonText: '返回'
                        }).then(() => {
                            this.onClickLeft()
                        });
                    }
                })
            },            
            isDuringDate(beginDateStr, endDateStr) {
                var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
                if (curDate >= beginDate && curDate <= endDate) {
                    return true;
                }
                return false;
            }
        }
    }
</script>

<style>
    .qr-code-block{
        background-color: #07c160;
        border: none; 
        height: 100vh;
    }
    .qr-code-block .van-nav-bar{
        background-color: #07c160;
        color: #FFFFFF;
        border: none;
    }
    .qr-code-block .van-nav-bar__title,.van-nav-bar__text,.van-nav-bar,.qr-code-block .van-icon{
        color: #FFFFFF;
    }
    
    .qr-code-block .content{
        height: calc(100% - 100px);
    }
</style>
